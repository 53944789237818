import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { COLORS, SIZES } from '../../styles/theme'
import config from '../../config'
import banner from '../../images/registration/banner.png'
import bannerM from '../../images/registration/mobile-banner.png'
import logo from '../../images/registration/event-logo.png'
import checkCircle from '../../images/registration/check_circle.png'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.postRegister.landscape});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  color: #FCB800;
  padding-right: 4.5em;
  padding-bottom: 4.5em;
  padding-top: 3em;
  img#banner-portrait {
    display: none;
  }
  
  #message {
    border: 1px solid white;
    border-radius: 32px;
    width: 65%;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    text-align: center;
    h5 {
      font-weight: 500;
      font-size: 20px;
    }
    h6 {
      color: white;
    }
  }
  .mobile-show {
    display: none;
  }
  #logo {
    width: 85%;
  }
  ${SIZES.mobile} {
    padding: 2.5em 0;
    background-image: url(${config.assets.postRegister.portrait});
    .mobile-hide {
      display: none;
    }
    .mobile-show {
      display: block;
    }
    img#banner-portrait {
      display: block;
    }
    #message {
      font-size: 0.75em;
      padding: 1.5rem 1rem;
      width: 85%;
    }
    .row {
      flex-direction: column-reverse;
      #logo {
        width: 70%;
      }
    }
  }
`

export default function PostRegisterPage() {

  return (
    <Page>
      <img id="banner" width="60%" src={banner} alt="event-banner" className="mx-auto mobile-hide" />
      <img id="banner-portrait" width="100%" src={bannerM} alt="event-banner" className="mx-auto" />
      <Row noGutters className="px-4 pl-md-0 pt-2 pb-3">
        <Col lg={4} className="align-self-center mt-4 mt-md-0">
          <img id="logo" src={logo} alt="event-logo" className="d-block mx-auto" />
        </Col>
        <Col lg={7} className="pr-md-4">
          <div id="message" className="mt-5">
            <img width="100px" src={checkCircle} alt="check-icon" className="d-block mx-auto" />
            <h5>
              Thank you for your registration and you will receive a confirmation email soon.
            </h5>
            <h6>
              <strong>Reminder: </strong>
              If you do not receive the Welcome Email, please check your spam folder or "Promotions" category in your Google inbox.
            </h6>
          </div>
        </Col>
      </Row>
    </Page>
  )
}
