import banner1 from '../images/event/Banner(LuckyDraw).jpg'
import banner2 from '../images/event/Banner(SignUpGIC).jpg'
import banner3 from '../images/event/Banner(Book-II).jpg'

export default [
  {
    id: "gift",
    url: "https://www.maxcapitalmanagement.com/lucky-draw",
    image: banner1,
  },
  {
    id: "member",
    url: "https://www.maxcapitalmanagement.com/gic-registration",
    image: banner2,
  },
  {
    id: "book",
    url: "https://www.maxcapitalmanagement.com/global-investment-book",
    image: banner3,
  },
]