import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js'
import { useParams, useHistory } from 'react-router-dom'
import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'
import { loadTawk } from '../utils/tawkToHelper'
import { getBooth, getBooths } from '../ducks/booth'
import { COLORS } from '../styles/theme'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import DownloadModal from '../components/GlobalModals/DownloadModal'
import IframeModal from '../components/GlobalModals/IframeModal'
import PosterModal from '../components/GlobalModals/PosterModal'
import InfoModal from '../components/GlobalModals/InfoModal'
import VideoModal from '../components/GlobalModals/VideoModal'
import TawkModal from '../components/GlobalModals/TawkModal'
import undoIcon from '../images/navigation/undo.png'
import photoIcon from '../images/booth/photo.png'
import pdfIcon from '../images/booth/pdficon.png'
import infoIcon from '../images/booth/info.png'
import leftIcon from '../images/booth/Arrow-left.png'
import rightIcon from '../images/booth/Arrow-right.png'
import boothBg from '../images/backdrops/boothBg.jpg'

const Page = styled.div`
  overflow: hidden;
  #button-container {
    position: absolute;
    bottom: 4vh;
    left: 2%;
    .hot-buttons {
      width: 125px;
    }
  }
  .hot-buttons,
  .hot-actions {
    /* background: linear-gradient(#e20613, #652781); */
    background-color: ${COLORS.primary};
    border-radius: 40px;
    border: 1px solid #bbbdbf;
    padding: 8px 16px;
    color: #bbbdbf;
    margin: 0 8px;
    &:hover {
      box-shadow: 0px 3px 12px #bbbdbf;
    }
    small {
      margin: 0;
      display: block;
      font-weight: 500;
      font-size: 70%;
    }
  }
  .booth-container {
    position: fixed;
    left: 50%;
    width: 75vw;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -65%);
    .hot-actions {
      border-radius: 50%;
      padding: 0;
      img,
      svg {
        transition: 500ms;
        width: 28px;
        height: 28px;
      }
      small {
        transition: 500ms;
        font-size: 1px;
        opacity: 0;
        color: white;
        display: none;
      }
      &:hover {
        box-shadow: 0px 3px 12px #bbbdbf;
        small {
          font-size: 8px;
          opacity: 1;
          display: block;
        }
        small.extra-small {
          font-size: 7px;
        }
        img,
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  #tawk-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 40vw;
    height: 40vh;
    z-index: 9999;
  }
  .exhibitor-title {
    text-align: right;
    width: 40vw;
    position: fixed;
    bottom: 3%;
    right: 5%;
    font-weight: 600;
  }
`
const HotButton = styled.button`
  position: absolute;
  width: 60px;
  height: 60px;
  ${(props) => props.css}
`

const EMBED_DIV_ID = 'TAWK_EMBED'

export default function BoothPage({ currentUser }) {
  const [showBtn, setShowBtn] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [posterOpen, setPosterOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [tawkOpen, setTawkOpen] = useState(false)
  const [playVideo, setPlayVideo] = useState({})

  const dispatch = useDispatch()
  const { viewBooth, booths } = useSelector((state) => state.booth)
  const { boothId } = useParams()
  const history = useHistory()

  useEffect(() => {
    dispatch(getBooth(boothId))
  }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.hall.id))
      amplitude.getInstance().logEvent(`Visit Booth`, {
        boothName: viewBooth.title,
      })
    }
  }, [boothId, currentUser.id, viewBooth])

  const handleModal = (video) => {
    setPlayVideo({
      title: 'Video',
      link: video + '?autoplay=1',
    })
    setOpen(true)
    setVideoOpen(false)
  }

  const handleDownload = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'pdf',
    })
    setDownloadOpen(!downloadOpen)
  }

  const handleVideoModal = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'video',
    })
    if (viewBooth.video_urls.length > 1) {
      setVideoOpen(!videoOpen)
    } else {
      setPlayVideo({
        title: 'Video',
        link: viewBooth.video_urls[0],
      })
      setOpen(true)
      setVideoOpen(false)
    }
  }
  const handlePoster = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'poster',
    })
    setPosterOpen(!posterOpen)
  }
  const handleInfo = () => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: viewBooth.title,
      hotButtonType: 'info',
    })
    setInfoOpen(!infoOpen)
  }

  const openTawk = () => {
    if (viewBooth) {
      amplitude.getInstance().logEvent('Click Booth HotButton', {
        boothName: viewBooth.title,
        hotButtonType: 'chat',
      })
      const { chat_link } = viewBooth
      loadTawk(currentUser, chat_link, EMBED_DIV_ID)
      setTawkOpen(!tawkOpen)
    }
  }

  const navigatePrev = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    history.push(`/booths/${navigateTo}`)
  }
  const navigateNext = () => {
    setShowBtn(false)
    const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    history.push(`/booths/${navigateTo}`)
  }

  return (
    <Page>
      <PageWithBg bgImg={boothBg}>
        {!showBtn && <LoadIcon />}
        {viewBooth && (
          <LazyLoadComponent>
            <div className="booth-container">
              <LazyLoadImage
                effect="black-and-white"
                afterLoad={() => setShowBtn(true)}
                className="booth-img"
                src={viewBooth.background_image.url}
                width="100%"
                alt="booth image"
              />
              {showBtn && (
                <>
                  {
                    viewBooth.video_urls.length!==0 &&
                    <HotButton
                      css={viewBooth.btnPos.vid}
                      className="hot-actions"
                      onClick={handleVideoModal}
                    >
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <polygon points="10 8 16 12 10 16 10 8"></polygon>
                      </svg>
                      <small>
                        Video
                        <br />
                        Gallery
                      </small>
                    </HotButton>
                  }
                  <HotButton
                    css={viewBooth.btnPos.pdf}
                    className="hot-actions"
                    onClick={handleDownload}
                  >
                    <img src={pdfIcon} width="26" alt="Nav" />
                    <small>
                      PDF
                      <br />
                      Gallery
                    </small>
                  </HotButton>
                  <HotButton
                    css={viewBooth.btnPos.image}
                    className="hot-actions"
                    onClick={handlePoster}
                  >
                    <img src={photoIcon} width="26" alt="Nav" />
                    <small>
                      Image
                      <br />
                      Gallery
                    </small>
                  </HotButton>
                  <HotButton
                    css={viewBooth.btnPos.info}
                    className="hot-actions"
                    onClick={handleInfo}
                  >
                    <img src={infoIcon} width="26" alt="Nav" />
                    <small>
                      Exhibitor
                      <br />
                      Info
                    </small>
                  </HotButton>
                  {/* <HotButton
                    css={viewBooth.btnPos.chat}
                    className="hot-actions"
                    onClick={openTawk}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        fill="#ffffff"
                        d="M20,2 L4,2 C2.9,2 2,2.9 2,4 L2,22 L6,18 L20,18 C21.1,18 22,17.1 22,16 L22,4 C22,2.9 21.1,2 20,2 Z M19,16 L6,16 L4,18 L4,5 C4,4.45 4.45,4 5,4 L19,4 C19.55,4 20,4.45 20,5 L20,15 C20,15.55 19.55,16 19,16 Z"
                      ></path>
                    </svg>
                    <small>
                      Talk
                      <br />
                      To Us
                    </small>
                  </HotButton> */}
                </>
              )}
            </div>
            <div id="button-container">
              <button
                className="hot-buttons"
                onClick={() =>
                  history.push(`/exhibition-hall/${viewBooth.hall.id}`)
                }
              >
                <img src={undoIcon} width="30" alt="Nav" />
                <small>Return to {viewBooth.hall.name}</small>
              </button>
              {viewBooth.order !== 0 && (
                <button className="hot-buttons" onClick={navigatePrev}>
                  <img src={leftIcon} width="15" alt="Nav" />
                  <small>Previous Booth</small>
                </button>
              )}
              {viewBooth.order !== booths.length-1 && (
                <button className="hot-buttons" onClick={navigateNext}>
                  <img src={rightIcon} width="15" alt="Nav" />
                  <small>Next Booth</small>
                </button>
              )}
            </div>
            <h4 className="exhibitor-title">{viewBooth.title}</h4>
            <VideoModal
              videos={viewBooth.video_urls}
              setVideoOpen={setVideoOpen}
              videoOpen={videoOpen}
              handleModal={handleModal}
            />
            <IframeModal
              iframeOpen={isOpen}
              setIframeOpen={setOpen}
              iframe={playVideo}
            />
            <DownloadModal
              files={viewBooth.documents}
              setDownloadOpen={setDownloadOpen}
              downloadOpen={downloadOpen}
            />
            <PosterModal
              images={viewBooth.images}
              posterOpen={posterOpen}
              setPosterOpen={setPosterOpen}
            />
            <InfoModal
              title={viewBooth.title}
              description={viewBooth.description}
              content={viewBooth.content}
              agents={viewBooth.booth_agents}
              infoOpen={infoOpen}
              setInfoOpen={setInfoOpen}
            />
            <TawkModal
              embedId={EMBED_DIV_ID}
              tawkOpen={tawkOpen}
              setTawkOpen={setTawkOpen}
            />
          </LazyLoadComponent>
        )}
      </PageWithBg>
    </Page>
  )
}
