import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import amplitude from 'amplitude-js'

import LoadIcon from '../../components/LoadIcon'
import materials from '../../constants/galleriumMaterials'

const MobileGalleriumVideosItem = ({ currentUser }) => {
  const { name } = useParams()
  const [loading, setLoading] = useState(true)
  const [number, setNumber] = useState(0)

  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit Gallerium-videos-podcast-${name}`)
  }, [currentUser.id, name])

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">{name}</p>
              <Link className="back-button" to="/gallerium/videos-podcast">
                Back
              </Link>
            </div>

            <div className="tab-row">
              {[0, 1, 2, 3].map((item, index) => (
                <p
                  className={`tab-row-item ${item === number ? 'active' : ''}`}
                  onClick={() => {
                    setNumber(item)
                  }}
                >
                  Video {`${item + 1}`}
                </p>
              ))}
            </div>

            <div className="video-container">
              {loading && <LoadIcon />}
              <iframe
                style={{ position: 'absolute', top: 0, left: 0 }}
                width="100%"
                height="100%"
                title="Event Trailer"
                src={
                  materials[1].materials.filter((item) => item.name === name)[0]
                    .video[number].link
                }
                allowFullScreen
                allow="autoplay; fullscreen"
                frameBorder="0"
                onLoad={() => {
                  setLoading(false)
                }}
              />
            </div>
          </div>
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  .tab-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .tab-row-item {
    font-size: 12px;
    color: #71030b;
    margin-bottom: 0px;
  }

  .active {
    text-decoration: underline;
    color: #e20613;
  }

  .video-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background: black;
    position: relative;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileGalleriumVideosItem
