import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import linkIcon from '../../images/mobile/link.png'
import routes from '../../routes'
import { useDispatch, useSelector } from 'react-redux'
import { getHalls } from '../../ducks/booth'
import LoadIcon from '../../components/LoadIcon2'

const MobileHallsDirectory = () => {
  const dispatch = useDispatch()
  const { halls } = useSelector((state) => state.booth)
  
  useEffect(() => {
    dispatch(getHalls())
  }, [dispatch])

  if (halls.length) {
    return (
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">Exhibition Halls</p>
              <Link className="back-button" to={routes.lobby}>
                Back
              </Link>
            </div>
  
            {halls.map((item, index) => (
              <Link to={`/exhibition-hall/${item.id}`} key={index}>
                <div className="item-wrapper">
                  <p className="item-title">{item.name}</p>
                  <img src={linkIcon} className="item-icon" />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </Page>
    )
  }
  return <LoadIcon />
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  a {
    color: black;
  }

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 20px 0px 20px 0px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    width: 70%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
  }

  .search-wrapper {
    width: 100%;
    height: 40px;
    border: solid 3px #662283;
    border-radius: 30px;
    padding: 0px 10px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .search-input {
    width: 70%;
    font-size: 14px;
    border: none;
  }

  .search-icon {
    height: 20px;
  }

  .item-wrapper {
    width: 100%;
    padding: 0px 20px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .item-title {
    width: 80%;
    font-size: 15px;
    margin: 0;
  }

  .item-icon {
    height: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileHallsDirectory
