import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import amplitude from 'amplitude-js'

import materials from '../../constants/galleriumMaterials'

const MobileGalleriumMagazines = ({ currentUser }) => {
  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit Gallerium-magazines`)
  }, [currentUser.id])

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">Magazines</p>
              <Link className="back-button" to="/gallerium">
                Back
              </Link>
            </div>

            <div className="list-container">
              {materials[2].materials.map((item, index) => (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="list-item"
                  key={index}
                >
                  <img alt="" src={item.image} className="list-image" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  .list-container {
    width: 100%;
    column-count: 2;
  }

  .list-item {
    width: 100%;
  }

  .list-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileGalleriumMagazines
