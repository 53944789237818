import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../routes.js';

import MobileLanding from './MobileLanding.js';
import MobileLogin from './MobileLogin.js';
import MobileForgotPassword from './MobileForgotPassword.js';
import config from '../../config';
import bannerM from '../../images/backdrops/mobile-banner-black.png'
import logo from '../../images/backdrops/event-logo-black.png'

const MobileEntrance = () => {
  return (
    <Page>
      <div id="blur"></div>
      <div id="content">
        <img width="100%" className="mt-3" src={bannerM} alt="event-banner" />
        <img width="50%" className="mb-4 mx-auto d-block" src={logo} alt="event-logo" />
        
        <Switch>
          <Route exact path={routes.root}>
            <MobileLanding />
          </Route>

          <Route exact path={routes.login}>
            <MobileLogin />
          </Route>

          <Route exact path={routes.forgotPassword}>
            <MobileForgotPassword />
          </Route>
        </Switch>
      </div>
    </Page>
  );
}

const Page = styled.div`
  background: url(${config.assets.entrance.portrait});
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;

  #content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
  }

  #blur {
    background-color: rgba(255,255,255,0.65);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(8px);
    height: 100%;
  }
`

export default MobileEntrance;