import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Axios from 'axios'
import config from '../../config'
// import routes from '../../routes'
// import { useObject } from 'react-firebase-hooks/database'
// import { AccessDb } from '../../firebaseConfig'
import LoadIcon from '../LoadIcon'

const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
  }
  .modal-body {
    padding: 16px;
  }
`

export default function PasscodeModal({ showModal, hideModal, setAuthenticated }) {
  const [ passcode, setPasscode ] = useState('')
  const history = useHistory()
  const [ isLoading, setIsLoading ] = useState(false)
  // const today = new Date()

  // only allow to access on 20 march
  // useEffect(() => {
  //   if ( today.getDate() !== 20 ){
  //     toast('Member Lounge will only be available to GIC members on 20 March, 2021 (Sat).', {
  //       toastId: 'eventToast',
  //     })
  //     history.push('/lobby')
  //   }
  // }, [])


  const handleClose = () => {
    hideModal()
    history.push('/lobby')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    Axios.post(`${config.apiUrl}/api/v1/members_lounge`, {
      passcode,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    })
      .then(function (response) {
        setIsLoading(false)
        if (response.data.success) {
          hideModal()
          setAuthenticated(true)
          // toast("Welcome to Members Lounge.")
        } else {
          toast("Invalid passcode. Please try again.")
        }
        setPasscode('')
      })
      .catch(function (error) {
        setIsLoading(false)
        toast(error.response.data.message)
      })
  }

  
  return (
    <StyledModal
      show={showModal}
      onHide={handleClose}
      size="md"
      aria-labelledby="auditorium-passcode"
      centered
    >
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>
              Member Lounge will only be available to GIC members on 20 March, 2021 (Sat) 2PM. Please enter your GIC member's passcode which has been emailed to you. <br />
              Please contact MCM Helpdesk if you need further assistance.
            </Form.Label>
            <Form.Control 
              placeholder="Passcode"
              value={passcode}
              onChange={(e)=>setPasscode(e.target.value)}
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button 
              type="submit" 
              className="mx-2" 
              onClick={handleSubmit}
              disabled={passcode.length < 4 || isLoading}
            >
              {isLoading ? 'Submitting...' : 'Confirm'}
            </Button>
            <Button className="mx-2" variant="danger" onClick={handleClose}>Cancel</Button>
          </div>
        </Form>
      </Modal.Body>
    </StyledModal>
  )
}
