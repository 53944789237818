import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import config from '../config'
import routes from '../routes'
import { COLORS, SIZES } from '../styles/theme'

const Page = styled.div`
  background: ${COLORS.primary};
  min-height: 100vh;
  width: 100vw;
  text-align: center;

  #content {
    position: absolute;
    top: 0;
    width:100%;
  }

  #header {
    padding-top: 3vh;

    h1 {
      color: white;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 0.8em;
      font-size: 2rem;
    }

    .time-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-transform: uppercase;

      .time-card {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        background: rgb(255, 255, 255);
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(242, 241, 241, 1) 70%,
          rgba(180, 179, 179, 1) 100%
        );

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        min-width: 120px;
        width: 10vw;
        margin: 0 1%;
        margin-top: 5px;
        padding-top: 10px;

        p {
          margin-bottom: 6px;
          font-weight: 500;
        }

        h2 {
          font-weight: 700;
          font-size: 3.2rem;
        }
      }
    }
  }
  .btn {
    background: #FD0000;
    color: #FFFFFF;
    font-weight: 500;
    padding: 4px 24px;
    border-radius: 32px;
  }
  .timeup {
    color: white;
    font-weight: 600;
    font-size: 24px;
  }
  #bg {
    position: relative;
    #register-btn {
      min-width: 225px;
      width: 20%;
      position: absolute;
      top: 50%;
      left: 80%;
      transform: translateX(-80%);
      
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      div {
        line-height: 30px;
      }
      div.arrow {
        line-height: 24px;
        font-size: 1.4em;
        align-self: flex-end;
      }
    }
    img#portrait {
      display: none;
    }
    img#landscape {
      display: block;
    }
    ${SIZES.mobile} {
      img#portrait {
        display: block;
      }
      img#landscape {
        display: none;
      }
      #register-btn {
        top: 55.5%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

`

export default function CountdownPage() {
  const calculateTimeLeft = () => {
    // year, actual month - 1, day, hour, minute, second, ignore
    // 13 Mar 2021 2pm
    const difference = +new Date(2021, 2, 13, 13, 30, 0, 0) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    localStorage.clear()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (isNaN(timeLeft[interval])) {
      return
    }

    timerComponents.push(
      <div className="time-card">
        <p>{interval}</p>
        <h2>{timeLeft[interval]}</h2>
      </div>
    )
  })
  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <Page>
      <div id="content">
        <div id="header">
          <h1>MCM | GIC Members Week 2021</h1>
          <div className="time-wrapper">
            {
              timerComponents.length ? 
              timerComponents 
              : 
              <span className="timeup">
                Time's up!
                <button className="btn ml-3 mb-1" onClick={refreshPage}>
                  Enter Event
                </button>
              </span>
            }
          </div>
        </div>
        <div id="bg">
          <img id="landscape" src={config.assets.countdown.landscape} width="100%" alt="background"/>
          <img id="portrait" src={config.assets.countdown.portrait} width="100%" alt="background"/>
          <Link to={routes.register} id="register-btn" className="btn">
            <div>REGISTER HERE</div>
            <div className="arrow">⇀</div>
          </Link>
        </div>
      </div>
    </Page>
  )
}
