import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import { useList } from 'react-firebase-hooks/database'
import Avatar from 'react-avatar'
import { toast } from 'react-toastify'
import amplitude from 'amplitude-js'
import { firebaseDb } from '../../firebaseConfig'
import config from '../../config'

import ChatCard from '../../components/Networking/ChatCard'
import UserDirectory from '../../components/Networking/UserDirectory'

import mobileNetworking from '../../images/mobile/mobile-networking.jpg'
import { COLORS } from '../../styles/theme'

const MobileNetworking = ({ currentUser }) => {
  const [chatLimit, setChatLimit] = useState(30)
  const [chatDb, setChatDb] = useState(
    firebaseDb.ref('chat').limitToLast(chatLimit)
  )
  const [snapshots, loading, error] = useList(chatDb)
  const [viewUser, setViewUser] = useState(null)
  const [showDirectory, setShowDirectory] = useState(false)
  const [users, setUsers] = useState([])
  const [dropNameCardLoading, setDropNameCardLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [conversations, setConversations] = useState(snapshots)

  useEffect(() => {
    Axios({
      url: `${config.apiUrl}/api/v1/users`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    }).then((result) => {
      setUsers(result.data.users)
    })
  }, [])

  useEffect(() => {
    setChatDb(firebaseDb.ref('chat').limitToLast(chatLimit))
  }, [chatLimit])

  useEffect(() => {

    amplitude.getInstance().logEvent(`Visit Networking Lounge`)
  }, [currentUser.id])

  useEffect(() => {
    if (!loading) {
      setConversations(snapshots)
    }
  }, [snapshots, loading])

  const refreshPage = () => {
    window.location.reload()
  }

  const toggleDirectory = () => {
    setShowDirectory(!showDirectory)
  }

  const sendMessage = (text) => {
    const payload = {
      dbUser: currentUser.fullName,
      dbMessage: text,
      dbTimestamp: Date.now(),
      dbUserId: currentUser.id,
    }
    if (currentUser.avatar) {
      payload.dbUserAvatarUrl = currentUser.avatar.url
    }
    firebaseDb.ref('chat').push(payload)
  }

  const handleCloseSubmitNameCard = () => {
    setMessage('')
    setViewUser(null)
  }

  const handleMessageInput = (e) => {
    setMessage(e.target.value)
  }

  const submitNamecard = () => {
    setDropNameCardLoading(true)
    amplitude.getInstance().logEvent('Drop name card', {
      SenderID: currentUser.id,
      SenderFullName: currentUser.fullName,
      SenderEmail: currentUser.email,
      ReceiverFullName: viewUser.fullName,
      ReceiverEmail: viewUser.email,
    })
    Axios({
      method: 'post',
      url: `${config.apiUrl}/api/v1/drop_namecard`,
      data: {
        receiver_email: viewUser.email,
        message,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    })
      .then(() => {
        toast(`An email has been sent to ${viewUser.fullName}!`)
        setViewUser(null)
      })
      .catch((err) => {
        setDropNameCardLoading(false)
        setMessage('')
        toast('Sending name card failed. Something went wrong.')
      })
      .finally(() => {
        setDropNameCardLoading(false)
        setMessage('')
        setViewUser(null)
      })
  }

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          {viewUser && (
            <div className="modal-container">
              <p
                className="modal-container-close"
                onClick={handleCloseSubmitNameCard}
              >
                Close
              </p>
              <div className="modal-container-content">
                <div className="profile-container">
                  <div className="profile-image">
                    {viewUser.avatar ? (
                      <img
                        alt="avatar"
                        src={viewUser.avatar.url}
                        className="avatar"
                      />
                    ) : (
                      <Avatar round name={viewUser.fullName} size="70px" />
                    )}
                  </div>
                  <div className="profile-info-container">
                    <p className="profile-info-name">{viewUser.fullName}</p>
                  </div>
                </div>
                <p>
                  Drop <b>{viewUser.fullName}</b> your name card!
                </p>
                <p>
                  This will send {viewUser.fullName} an email with your contact
                  information indicating that you wish to connect.
                </p>
                <p>You can also include custom message:</p>
                <textarea
                  type="text"
                  placeholder="(Optional)"
                  value={message}
                  onChange={handleMessageInput}
                  className="input"
                />
                <button
                  type="button"
                  className="drop-namecard-button"
                  onClick={submitNamecard}
                  disabled={dropNameCardLoading}
                >
                  {dropNameCardLoading ? 'Submitting' : 'Drop your name card'}
                </button>
              </div>
            </div>
          )}

          {!showDirectory && (
            <React.Fragment>
              {error ? (
                <button onClick={refreshPage}>
                  Connection failed. Click here to refresh.
                </button>
              ) : (
                <div className="chat-header">
                  <header className="d-flex justify-content-between align-items-end">
                    <h6>Networking Lounge</h6>
                    <div>
                      <button
                        className="contact-list d-flex"
                        onClick={toggleDirectory}
                      >
                        <svg width="26" height="26" viewBox="0 0 48 48">
                          <path
                            fill="#312782"
                            d="M23.896,31.209c2.59-1.72,4.302-4.659,4.302-7.994c0-5.29-4.303-9.593-9.593-9.593
                          c-5.29,0-9.593,4.303-9.593,9.593c0,3.335,1.712,6.275,4.302,7.994c-5.86,2.162-10.057,7.787-10.057,14.389h2.558
                          c0-7.053,5.737-12.79,12.79-12.79c7.053,0,12.79,5.737,12.79,12.79h2.558C33.953,38.996,29.756,33.371,23.896,31.209z
                          M18.605,30.249c-3.879,0-7.035-3.156-7.035-7.035s3.156-7.035,7.035-7.035s7.035,3.156,7.035,7.035S22.484,30.249,18.605,30.249z"
                          />
                          <path
                            fill="#312782"
                            d="M36.686,24.814c2.59-1.72,4.302-4.659,4.302-7.994c0-5.29-4.303-9.593-9.593-9.593
                          c-2.563,0-4.888,1.016-6.61,2.659c-0.638,0.608-1.189,1.304-1.641,2.066c0.798,0.323,1.557,0.721,2.261,1.198
                          c0.452-0.736,1.034-1.381,1.717-1.905c1.186-0.912,2.665-1.46,4.274-1.46c3.879,0,7.035,3.156,7.035,7.035
                          c0,3.22-2.177,5.935-5.135,6.767c-0.605,0.17-1.241,0.268-1.9,0.268c-0.226,0-0.449-0.013-0.67-0.034
                          c-0.046,0.921-0.19,1.814-0.431,2.671c0.366-0.032,0.727-0.079,1.101-0.079c0.519,0,1.029,0.04,1.532,0.101
                          c6.332,0.761,11.258,6.156,11.258,12.689h2.558C46.743,32.601,42.546,26.976,36.686,24.814z"
                          />
                        </svg>
                        <span className="mx-1">Who's here</span>
                      </button>
                    </div>
                  </header>
                  <div className="divider"></div>
                  <div className="topics">
                    <span className="font-weight-bold">
                      Say hi and join our discussion here
                    </span>
                  </div>
                  <div className="divider"></div>
                  <ChatCard
                    setViewUser={setViewUser}
                    conversations={conversations}
                    loading={loading}
                    sendMessage={sendMessage}
                    currentUser={currentUser}
                    chatLimit={chatLimit}
                    setChatLimit={setChatLimit}
                  />
                </div>
              )}
            </React.Fragment>
          )}

          {showDirectory && (
            <UserDirectory
              users={users}
              setViewUser={setViewUser}
              toggleDirectory={toggleDirectory}
            />
          )}
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: url(${mobileNetworking});
  background-size: cover;
  background-position: 70% 50%;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
    max-width: 540px;
    margin: auto;
    height: 100%;
  }
  .chat-header {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 32px 24px;
    header {
      padding-bottom: 4px;
      margin-bottom: 8px;
      h6 {
        font-weight: 600;
        text-align: left;
        color: ${COLORS.primary};
      }
      .contact-list {
        align-self: flex-end;
        cursor: pointer;
        margin: 0 8px;
        line-height: 30px;
        vertical-align: middle;
        border: none;
        outline: none;
        background: transparent;
        color: #312782;
      }
    }

    .topics {
      display: flex;
      margin: 8px;

      button {
        border-radius: 50%;
        border: none;
        background: transparent;
      }
    }
    .divider {
      background: linear-gradient(270deg, #fe1e9a, #254dde);
      height: 2px;
    }
  }

  .modal-container {
    width: 95%;
    max-width: calc(0.95 * 540px);
    height: 60%;
    border-radius: 30px;
    border: solid 2px #5c628a;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .modal-container-close {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-bottom: 0;
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .modal-container-content {
    width: calc(100% - 60px);
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
    font-size: 12px;
  }

  .profile-container {
    width: 100%;
    height: 100px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
  }

  .profile-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 20px;
    /*background: #02c6c0;*/
    background: lightgrey;
  }

  .profile-info-container {
    width: calc(100% - 90px);
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .profile-info-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .drop-namecard-button {
    background: #522c86;
    font-size: 14px;
    color: white;
    width: 100%;
    border: none;
    border-radius: 30px;
    height: 40px;
    margin-bottom: 30px;
  }

  .drop-namecard-button:disabled {
    opacity: 0.5;
  }

  .input {
    padding: 10px 15px;
    border-radius: 10px;
    border: solid 2px #707070;
    width: 100%;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`

export default MobileNetworking
