import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import amplitude from 'amplitude-js'

import materials from '../../constants/galleriumMaterials'

const MobileGalleriumInfographics = ({ currentUser }) => {
  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit Gallerium-infographics`)
  }, [currentUser.id])

  return (
    <div>
      <Page>
        <div className="outer-wrapper">
          <div className="container">
            <div className="title-container">
              <p className="title">Infographics</p>
              <Link className="back-button" to="/gallerium">
                Back
              </Link>
            </div>

            <div className="item-row">
              {materials[0].materials.slice(0, 2).map((item, index) => (
                <Link
                  to={`/gallerium/infographics/${item.name}`}
                  className="item-container"
                  key={index}
                >
                  <img alt="" src={item.image} className="item-image" />
                </Link>
              ))}
            </div>

            <div className="item-row">
              {materials[0].materials.slice(2, 4).map((item, index) => (
                <Link
                  to={`/gallerium/infographics/${item.name}`}
                  className="item-container"
                  key={index}
                >
                  <img alt="" src={item.image} className="item-image" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </Page>
    </div>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  .item-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .item-container {
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #f8f4ff;
  }

  .item-image {
    width: 100%;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileGalleriumInfographics
