import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import amplitude from 'amplitude-js'
import LoadIcon from '../../components/LoadIcon'
import ChatSection from '../../components/ChatSection.js'
import { muteMusic, unmuteMusic } from '../../ducks/layout'
import agenda1 from '../../images/event/Agenda1.jpg'
import agenda2 from '../../images/event/Agenda2.jpg'
import agenda3 from '../../images/event/Agenda3.jpg'
import agenda4 from '../../images/event/Agenda4.jpg'
import agenda5 from '../../images/event/Agenda5.jpg'
import agenda6 from '../../images/event/Agenda6.jpg'
import agenda7 from '../../images/event/Agenda7.jpg'
import openTheatre from '../../images/mobile/open-theatre.png'
import closeTheatre from '../../images/mobile/close-theatre.png'
import vote from '../../images/event/vote.png'
import screen from '../../images/mobile/screen.png'
import { ChatAuditoriumDb, AuditoriumDb, LiveLinkDb } from '../../firebaseConfig'
import config from '../../config'
import routes from '../../routes'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useObject } from 'react-firebase-hooks/database'
import { startTrack, endTrack } from '../../utils/timerHelper'
import { usePageActiveUserCount } from '../../utils/activeUserCount'
import IframeModal from '../../components/GlobalModals/IframeModal'

const MobileAuditorium = ({ currentUser, liveUrl }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [agendaModal, setAgendaModal] = useState(false)
  const [theatre, setTheatre] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [snapshot, loading, error] = useObject(AuditoriumDb)
  const [liveSnapshot, liveLoading, liveError] = useObject(LiveLinkDb)
  const initialPath = history.location.pathname.substr(1)
  const playBackLink = {
    title: "Event Playbacks",
    link: 'https://vimeo.com/showcase/8238916/embed',
  }

  usePageActiveUserCount(currentUser)

  // useEffect(() => {
  //   if (currentUser.id){
  //     startTrack(currentUser)
  //   }
    
  //   return () => {
  //     if (currentUser.id){
  //       endTrack(currentUser, initialPath)
  //     }
  //   }
  // }, [currentUser.id])

  useEffect(() => {
    if (!loading) {
      if (snapshot.val()){
        amplitude.getInstance().logEvent(`Visit Auditorium`)
      } else {
        toast('Auditorium Hall currently not available. Please try again later.', {
          toastId: 'auditoriumToast',
        })
        history.push(routes.lobby)
      }
    }
  }, [loading, snapshot, currentUser.id])

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  const toggleAgendaModal = () => {
    if (agendaModal === false)
      amplitude.getInstance().logEvent('Click Agenda')
    setAgendaModal(!agendaModal)
  }

  const showVideo = () => {
    amplitude.getInstance().logEvent('Click Playbacks')
    setIsOpen(true)
  }

  const videoContainerTheatreStyle = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'black',
    zIndex: '99',
  }

  const showVote = () => {
    amplitude.getInstance().logEvent(`Click Auditorium Vote`)
    window.open(
      "http://bit.ly/MCM-UDGP-The-Best-UDGP-Presentation-Voting-Form",
      "_blank"
    )
  }

  if (loading || liveLoading) {
    return <LoadIcon />
  }
  return (
    <Page>
      {agendaModal && (
        <div className="modal-container">
          <p className="modal-container-close" onClick={toggleAgendaModal}>
            Close
          </p>
          <div className="modal-container-content">
            <img alt="agenda" src={agenda1} className="content-image" />
            <img alt="agenda" src={agenda2} className="content-image" />
            <img alt="agenda" src={agenda3} className="content-image" />
            <img alt="agenda" src={agenda4} className="content-image" />
            <img alt="agenda" src={agenda5} className="content-image" />
            <img alt="agenda" src={agenda6} className="content-image" />
            <img alt="agenda" src={agenda7} className="content-image" />
          </div>
        </div>
      )}
      <div className="outer-wrapper">
        <div className="container">
          <div className="screen-container">
            <img src={screen} className="screen" />
            <div
              className="video-container"
              style={theatre ? videoContainerTheatreStyle : {}}
            >
              {isLoading && <LoadIcon />}
              <iframe
                title="Live Broadcast"
                src={liveSnapshot.val()}
                allow="autoplay; fullscreen"
                allowFullScreen
                frameBorder="0"
                onLoad={() => {
                  setIsLoading(false)
                }}
              />
            </div>
          </div>

          <button
            type="button"
            className="theatre-button"
            onClick={() => setTheatre(true)}
          >
            <img src={openTheatre} className="theatre-button-icon" />
            <p className="theatre-button-label">Theatre mode</p>
          </button>
          <div className="buttons-container">
            <button className="actions-btn" onClick={toggleAgendaModal}>Agenda</button>
            <button className="actions-btn" onClick={showVideo}>Playbacks</button>
            <div onClick={showVote} className="actions-div">
              <img src={vote} alt="vote" width="100%" />
            </div>
          </div>
        </div>

        <div className="chat-container">
          <ChatSection
            currentUser={currentUser}
            firebaseDb={ChatAuditoriumDb}
            hallName="Auditorium"
          />
        </div>

        {theatre && (
          <div className="theatre-close">
            <img
              alt="close-icon"
              src={closeTheatre}
              onClick={() => setTheatre(false)}
              className="theatre-close-icon"
            />
          </div>
        )}
      </div>
      <IframeModal 
        iframe={playBackLink}
        iframeOpen={isOpen}
        setIframeOpen={setIsOpen}
      />
    </Page>
  )
}

const Page = styled.div`
  background: url(${config.assets.postRegister.portrait});
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 95%;
    margin: auto;
    margin-top: 32px;
  }

  .checkin-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0px 20px 0px;
  }

  .checkin {
    width: 38%;
    height: auto;
  }

  .screen-container {
    width: 100%;
    height: 0;
    padding-bottom: 58.5%;
    position: relative;
  }

  .screen {
    width: 100%;
    position: absolute;
  }

  .video-container {
    width: 92%;
    height: 81%;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);

    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .buttons-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }
  .actions-btn {
    width: 125px;
    background-image: linear-gradient(#492777, #c4000c);
    border: 2px solid #e20613;
    border-radius: 16px;
    color: white;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
    margin: 0 8px;
    padding: 6px 0;
    letter-spacing: 1px;
  }
  .actions-div {
    width: 125px;
  }
  .agenda {
    width: 45%;
    height: auto;
  }
  .title {
    width: 100%;
    margin: 20px 0px;
  }

  .chat-container {
    width: 100%;
    height: 500px;
    margin-top: 36px;
  }

  .modal-container {
    width: 95%;
    height: 60%;
    border-radius: 30px;
    border: solid 2px #652781;
    position: fixed;
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    z-index: 99;
  }

  .modal-container-close {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-bottom: 0;
    position: absolute;
    top: 20px;
    right: 30px;
  }

  .modal-container-content {
    width: calc(100% - 60px);
    height: calc(100% - 70px);
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: scroll;
  }

  .content-image {
    width: 100%;
    margin-bottom: 8px;
  }

  .theatre-button {
    width: 140px;
    height: 30px;
    background: black;
    margin: 10px 10px 0px 0px;
    float: right;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0.6;
    padding: 0;
  }

  .theatre-button-label {
    color: white;
    font-size: 12px;
    margin: 0px 10px 0px 0px;
  }

  .theatre-button-icon {
    width: 20px;
    margin: 0px 10px;
  }

  .theatre-close {
    color: white;
    background: black;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 99;
    opacity: 0.6;
  }

  .theatre-close-icon {
    width: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileAuditorium
