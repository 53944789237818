import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import amplitude from 'amplitude-js'
import search from '../../images/mobile/search.png'
import linkIcon from '../../images/mobile/link.png'
import { useDispatch, useSelector } from 'react-redux'
import { getBooths, getHalls } from '../../ducks/booth'

const MobileExpoDirectory = ({ currentUser }) => {
  const dispatch = useDispatch()
  const { booths, halls } = useSelector((state) => state.booth)
  const params = useParams()
  const hallNumber = parseInt(params.hallId)
  const [boothList, setBoothList] = useState(booths)
  const [searchInput, setSearchInput] = useState('')
  
  useEffect(() => {
    dispatch(getBooths(hallNumber))
    dispatch(getHalls())
  }, [hallNumber, dispatch])

  useEffect(() => {
    if (searchInput !== '') {
      const newBoothList = booths.filter((item) =>
        new RegExp(searchInput.toLowerCase()).test(item.title.toLowerCase())
      )
      setBoothList(newBoothList)
    } else {
      setBoothList(booths)
    }
  }, [booths, searchInput])

  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
      hallNumber: hallNumber,
    })
  }, [currentUser.id, hallNumber])

  const getHallName = () => {
    const hall = halls.filter(item=>item.id === hallNumber)[0]
    return hall
  }

  const onChangeSearchInput = (e) => {
    setSearchInput(e.target.value)
  }

  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <div className="title-container">
            <p className="title">{getHallName() && getHallName().name}</p>
            <Link className="back-button" to="/exhibition-halls">
              Back
            </Link>
          </div>
          <div className="search-wrapper">
            <input
              value={searchInput}
              onChange={onChangeSearchInput}
              type="text"
              placeholder="Search exhibitor"
              className="search-input"
            />
            <img src={search} className="search-icon" />
          </div>

          {boothList.map((item, index) => (
            <Link to={`/booths/${item.id}`} key={index}>
              <div className="item-wrapper">
                <p className="item-title">{item.title}</p>
                <img src={linkIcon} className="item-icon" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  a {
    color: black;
  }

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 20px 0px 10px 0px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    width: 70%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
  }

  .search-wrapper {
    width: 100%;
    height: 40px;
    border: solid 3px #662283;
    border-radius: 30px;
    padding: 0px 10px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .search-input {
    width: 70%;
    font-size: 14px;
    border: none;
  }

  .search-icon {
    height: 20px;
  }

  .item-wrapper {
    width: 100%;
    padding: 0px 20px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .item-title {
    width: 80%;
    font-size: 15px;
    margin: 0;
  }

  .item-icon {
    height: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileExpoDirectory
