import thumb1 from '../images/gallerium/thumbnails/1-MCMChronologyVideo.png'
import thumb2 from '../images/gallerium/thumbnails/2-MCMCorporateVideo.png'
import thumb3 from '../images/gallerium/thumbnails/3-88Holidays.png'
import thumb4 from '../images/gallerium/thumbnails/4-Boutir.png'
import thumb5 from '../images/gallerium/thumbnails/5-CelikWang.png'
import thumb6 from '../images/gallerium/thumbnails/6-Code7.png'
import thumb7 from '../images/gallerium/thumbnails/7-Creamerie.png'
import thumb8 from '../images/gallerium/thumbnails/8-Devibiz.png'
import thumb9 from '../images/gallerium/thumbnails/9-E2EAcademy.png'
import thumb10 from '../images/gallerium/thumbnails/10-EntreVestors.png'
import thumb11 from '../images/gallerium/thumbnails/11-EntreVestors.png'
import thumb12 from '../images/gallerium/thumbnails/12-Foundx.png'
import thumb13 from '../images/gallerium/thumbnails/13-IMAX.png'
import thumb14 from '../images/gallerium/thumbnails/14-JOCOM.png'
import thumb15 from '../images/gallerium/thumbnails/15-KebabBowl.png'
import thumb16 from '../images/gallerium/thumbnails/16-Made4u.png'
import thumb17 from '../images/gallerium/thumbnails/17-MaxWealth.png'
import thumb18 from '../images/gallerium/thumbnails/18-MLTgenMay.png'
import thumb19 from '../images/gallerium/thumbnails/19-MoneyCompass.png'
import thumb20 from '../images/gallerium/thumbnails/20-MyLifeAssociation.png'
import thumb21 from '../images/gallerium/thumbnails/21-MysticalMandalaHealingSolutions.png'
import thumb22 from '../images/gallerium/thumbnails/22-SBS.png'
import thumb23 from '../images/gallerium/thumbnails/23-ULearnMoney.png'
import thumb24 from '../images/gallerium/thumbnails/24-ViimenTV.png'
import thumb25 from '../images/gallerium/thumbnails/25-WIMCollege.png'
import thumb26 from '../images/gallerium/thumbnails/26-WIMCollege.png'
import thumb27 from '../images/gallerium/thumbnails/27-WIMCollege.png'

const featured = [
  {
    id: 1,
    videoId: '522780451',
    link:'https://player.vimeo.com/video/522780451',
    image: thumb1,
  },
  {
    id: 2,
    videoId: '522780266',
    link:'https://player.vimeo.com/video/522780266',
    image: thumb2,
  },
]
const exhibitors = [
  {
    id: 3,
    videoId: '522756169',
    link:'https://player.vimeo.com/video/522756169',
    image: thumb3,
  },
  {
    id: 4,
    videoId: '522756205',
    link:'https://player.vimeo.com/video/522756205',
    image: thumb4,
  },
  {
    id: 5,
    videoId: '522756438',
    link:'https://player.vimeo.com/video/522756438',
    image: thumb5,
  },
  {
    id: 6,
    videoId: '522756663',
    link:'https://player.vimeo.com/video/522756663',
    image: thumb6,
  },
  {
    id: 7,
    videoId: '522756757',
    link:'https://player.vimeo.com/video/522756757',
    image: thumb7,
  },
  {
    id: 8,
    videoId: '522756825',
    link:'https://player.vimeo.com/video/522756825',
    image: thumb8,
  },
  {
    id: 9,
    videoId: '522756952',
    link:'https://player.vimeo.com/video/522756952',
    image: thumb9,
  },
  {
    id: 10,
    videoId: '522756997',
    link:'https://player.vimeo.com/video/522756997',
    image: thumb10,
  },
  {
    id: 11,
    videoId: '522757194',
    link:'https://player.vimeo.com/video/522757194',
    image: thumb11,
  },
  {
    id: 12,
    videoId: '522757384',
    link:'https://player.vimeo.com/video/522757384',
    image: thumb12,
  },
  {
    id: 13,
    videoId: '522757485',
    link:'https://player.vimeo.com/video/522757485',
    image: thumb13,
  },
  {
    id: 14,
    videoId: '522757724',
    link:'https://player.vimeo.com/video/522757724',
    image: thumb14,
  },
  {
    id: 15,
    videoId: '522757781',
    link:'https://player.vimeo.com/video/522757781',
    image: thumb15,
  },
  {
    id: 16,
    videoId: '522757823',
    link:'https://player.vimeo.com/video/522757823',
    image: thumb16,
  },
  {
    id: 17,
    videoId: '522758526',
    link:'https://player.vimeo.com/video/522758526',
    image: thumb17,
  },
  {
    id: 18,
    videoId: '522758666',
    link:'https://player.vimeo.com/video/522758666',
    image: thumb18,
  },
  {
    id: 19,
    videoId: '522758883',
    link:'https://player.vimeo.com/video/522758883',
    image: thumb19,
  },
  {
    id: 20,
    videoId: '522759127',
    link:'https://player.vimeo.com/video/522759127',
    image: thumb20,
  },
  {
    id: 21,
    videoId: '522759585',
    link:'https://player.vimeo.com/video/522759585',
    image: thumb21,
  },
  {
    id: 22,
    videoId: '522760599',
    link:'https://player.vimeo.com/video/522760599',
    image: thumb22,
  },
  {
    id: 23,
    videoId: '522760627',
    link:'https://player.vimeo.com/video/522760627',
    image: thumb23,
  },
  {
    id: 24,
    videoId: '522760696',
    link:'https://player.vimeo.com/video/522760696',
    image: thumb24,
  },
  {
    id: 25,
    videoId: '522760844',
    link:'https://player.vimeo.com/video/522760844',
    image: thumb25,
  },
  {
    id: 26,
    videoId: '522760889',
    link:'https://player.vimeo.com/video/522760889',
    image: thumb26,
  },
  {
    id: 27,
    videoId: '522760939',
    link:'https://player.vimeo.com/video/522760939',
    image: thumb27,
  },
]

export { featured, exhibitors };