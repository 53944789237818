import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import amplitude from 'amplitude-js'
import play2 from '../../images/mobile/play2.png'
import { featured, exhibitors } from '../../constants/galleryList'
import routes from '../../routes'
import { Row, Col } from 'react-bootstrap'
import ModalVideo from 'react-modal-video'

const MobileGalleriumVideos = ({ currentUser }) => {
  const [video, setVideo] = useState(null)
  const [videoOpen, setVideoOpen] = useState(false)

  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit Gallery`)
  }, [currentUser.id])

  const openVideo = (video) => {
    setVideo(video)
    setVideoOpen(true)
    amplitude.getInstance().logEvent(`Click gallery video`, {
      videoId: video.id
    })
  }

  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <div className="title-container">
            <p className="title">Gallery</p>
            <Link className="back-button" to={routes.lobby}>
              Back
            </Link>
          </div>

          <Row noGutters className="item-row">
            {featured.map((item, index) => (
              <Col xs={6} key={index}>
                <div
                  onClick={() => openVideo(item)}
                  className="item-container"
                >
                  <img src={item.image} className="item-image" />
                  <img src={play2} className="item-icon" />
                </div>
              </Col>
            ))}
            {exhibitors.map((item, index) => (
              <Col xs={6} key={index}>
                <div
                  onClick={() => openVideo(item)}
                  className="item-container"
                >
                  <img src={item.image} className="item-image" />
                  <img src={play2} className="item-icon" />
                </div>
              </Col>
            ))}
          </Row>

        </div>
      </div>
      {video && (
        <ModalVideo
          channel="vimeo"
          autoplay
          isOpen={videoOpen}
          videoId={video.videoId}
          onClose={() => setVideoOpen(false)}
        />
      )}
    </Page>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }

  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  /* .item-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  } */

  .item-container {
    margin: 0 auto;
    margin-bottom: 20px;
    width: 140px;
    height: 140px;
    position: relative;
  }

  .item-image {
    width: 100%;
    position: absolute;
  }

  .item-icon {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (min-width: 540px) {
    .item-container {
      margin: 0 auto;
      margin-bottom: 20px;
      width: 160px;
      height: 160px;
      position: relative;
    }
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileGalleriumVideos
