import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'

import banner from '../../images/registration/banner.png'
import bannerM from '../../images/registration/mobile-banner.png'
import logo from '../../images/registration/event-logo.png'

import { COLORS, SIZES } from '../../styles/theme'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.postRegister.landscape});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:left;
  padding-top: 1em;
  padding-right: 1.5em;
  padding-bottom: 4.5em;
  color: #FCB800;
  img#banner-portrait {
    display: none;
  }
  ${SIZES.mobile} {
    background-image: url(${config.assets.postRegister.portrait});
    padding: 0;
    padding-bottom: 2.5em;
    padding-top: 1em;
    font-size: 0.9em;
    img#banner-portrait {
      display: block;
    }
    .mobile-hide {
      display: none;
    }
  }
  
  .row {
    h6 {
      text-align: center;
      margin: 16px;
      font-weight: 600;
      ${SIZES.mobile} {
        margin: 8px;
      }
    }
    h6.subtitle {
      font-weight: 600;
      margin: 24px 0;
    }
    .register-form {
      padding: 2.5em 0;

      .form-group {
        width: 100%;
        margin: 8px auto;
        background: #F8F4FF;
        border: 1px solid #0269EA;
        border-radius: 25px;
        font-weight: 600;
        text-align: center;
        padding-right: 16px;
        label {
          color: #00469D;
          padding: 0 8px;
          margin: 0 auto;
          padding-right: 0;
          font-size: 13px;
        }
        .required:before {
          content:"* ";
          color: red;
        }
        input[type='tel'],
        .form-input {
          display: block;
          color: #002F62;
          letter-spacing: 1px;
          width: 100%;
          font-weight: 500;
          background: transparent;
          border: none;
          padding: 4px 24px;
          outline: none;
        }
        .react-tel-input {
          color: #279AAC;
          input[type='tel'] {
            padding-left: 56px;
            height: 35px;
            box-shadow: none;
          }
          .country-list {
            margin: 0;
          }
          .flag-dropdown {
            background: transparent;
            border: none;
            padding-left: 16px;
            .selected-flag {
              background: transparent;
            }
          }
        }
      }
      .help-text {
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
      }
      #password {
        width: 12px;
        margin: 2px 4px;
      }
    }
  }
  .form-check {
    display:flex;
    align-items: flex-start;
    .form-check-label {
      color: #FFFFFF;
      font-weight: 500;
      font-size: 14px;
      margin-left: 8px;
      a {
        color: #FCB800;
        text-decoration: underline;
      }
    }
  }
  
  button.submit-btn {
    background: #FD0000;
    width: 80%;
    color: #FFFFFF;
    font-weight: 500;
    font-style: italic;
    /* border: 1px solid ${COLORS.primary}; */
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    margin: 16px auto;
    margin-bottom: 0;
    font-size: 1em;
    outline: none;
    box-shadow: none;

    ${SIZES.mobile} {
      padding: 4px 24px;
      width: 100%;
    }

    &:disabled {
      cursor: not-allowed;
      background: grey;
      border: solid 1px ${COLORS.primary};
    }

    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function RegisterFormPage() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('60')
  const [agree, setAgree] = useState(false)

  const handleCheckBox = (e) => {
    setAgree(e.target.checked)
  }
  const handleEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const options = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      user_name: userName.trim(),
      email,
      phone,
    }
    Axios.post(`${config.apiUrl}/api/v1/register`, options)
      .then(() => {
        setLoading(false)
        history.push(routes.postRegister)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    email.length &&
    firstName.length &&
    lastName.length &&
    userName.length &&
    phone.length >= 7 &&
    agree
  )

  return (
    <Page>
      <img id="banner" width="60%" src={banner} alt="event-banner" className="mx-auto mobile-hide" />
      <img id="banner-portrait" width="100%" src={bannerM} alt="event-banner" className="mx-auto" />
      <Row id="main" noGutters className="px-4 pl-md-0 pt-2 pb-3">

        <Col md={4} className="mobile-hide align-self-center">
          <img width="90%" src={logo} alt="event-logo" className="d-block mx-auto" />
        </Col>

        <Col md={12} lg={4} className="px-md-3">
          <h6 className="subtitle">Kindly fill in your details below to complete registration</h6>
          {hasEmptyField && (
            <p className="text-danger m-0">
              * Please fill in the required field to proceed.
            </p>
          )}
          <Form className="register-form py-3">
            <Form.Group>
              <label className="required">First Name</label>
              <input
                className="form-input"
                required
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <label className="required">Last Name</label>
              <input
                className="form-input"
                required
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <label className="required">
                User Name <span className="help-text text-secondary">(for Mobile App's Login)</span>
              </label>
              <input
                className="form-input"
                required
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <label className="required">Mobile Number</label>
              <PhoneInput
                inputProps={{ required: true }}
                className="form-input"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
            </Form.Group>
            <Form.Group>
              <label className="required">Email Address</label>
              <input
                className="form-input"
                required
                type="email"
                value={email}
                onChange={handleEmailInput}
              />
            </Form.Group>
          </Form>
            
          <div className="form-check">
            <input 
              type="checkbox" 
              id="agree-box" 
              onChange={handleCheckBox}
            />
            <label className="form-check-label">
              <span className="text-danger">*</span>&nbsp;I have read&nbsp;
              <a href="https://drive.google.com/file/d/1Hwe-yBoVJQhWXQZiqoq8ueV2o_1hoxD3/view" target="_blank" rel="noopener noreferrer">
                disclaimers
              </a>
                &nbsp;and agree to&nbsp;
              <a href="https://drive.google.com/file/d/1Hwe-yBoVJQhWXQZiqoq8ueV2o_1hoxD3/view" target="_blank" rel="noopener noreferrer" >
                Terms and Conditions
              </a>
            </label>
          </div>
          <div className="w-100">
            <Button
              type="submit"
              className="submit-btn"
              variant="default"
              disabled={hasEmptyField || loading}
              onClick={handleSubmit}
            >
              <div>{loading ? 'Loading...' : 'SUBMIT'}</div>
              <div className="arrow">⇀</div>
            </Button>
          </div>
        </Col>
      </Row>
    </Page>
  )
}
