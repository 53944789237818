import React, { useEffect } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js'
import { Link, useParams } from 'react-router-dom'
import config from '../config'
import { useDispatch, useSelector } from 'react-redux'
import { getBooths, getHalls } from '../ducks/booth'
import { Row, Col } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { COLORS } from '../styles/theme'
import poster from '../images/backdrops/hall-poster.jpg'

const Page = styled.div`
  overflow: hidden;
  background-image: ${(props)=> `url(${props.hallBg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 30px;
  .next-btn, .prev-btn {
    position: absolute;
    bottom: 8vh;
    right: 5%;
    text-decoration: none;
    /* background: linear-gradient(#e20613, #652781); */
    background-color: ${COLORS.primary};
    color: #bbbdbf;
    border-radius: 32px;
    border: 2px solid #BBBDBF;
    padding: 8px 16px;
    text-align: center;
    p {
      margin: 0 auto;
    }
    &:hover {
      box-shadow: 0px 3px 12px #BBBDBF;
    }
  }
  .prev-btn {
    bottom: 8vh;
    right: 85%;
  }
  #hall {
    padding: 100px 30px;
    padding-bottom: 50px;
    padding-right: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .booths {
    margin: 10px;
    margin-top: 20px;
    width: 15vw;
    /* height: 25vh; */
    -webkit-transition: margin 0.2s ease-out;
    -moz-transition: margin 0.2s ease-out;
    -o-transition: margin 0.2s ease-out;

    &:hover {
      cursor:pointer;
      margin-top: 5px;
    }
  }
  #banner {
    position: fixed;
    height: 100%;
    width: 100%;
    img {
      width: 23%;
      max-height: 100%;
      display: block;
      margin: 0 auto;
      margin-top: -0.5rem;
    }
  }
`

export default function ExhibitionPage({
  hallBg,
  currentUser,
}) {
  const dispatch = useDispatch()
  const { booths, halls } = useSelector((state) => state.booth)
  const params = useParams()
  const hallNumber = parseInt(params.hallId)
  const boothPerRow = 5

  useEffect(() => {
    dispatch(getBooths(hallNumber))
    dispatch(getHalls())
  }, [hallNumber, dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Exhibition Hall`
  }, [])

  useEffect(() => {
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
      hallNumber: hallNumber,
    })
  }, [currentUser.id, hallNumber])

  const getNextHall = () => {
    return hallNumber+1
  }
  const getPreviousHall = () => {
    return hallNumber-1
  }

  const getNextHallName = () => {
    if (hallNumber === halls.length) {
      return null
    }
    const hall = halls.filter(item=>item.id === hallNumber+1)[0]
    return hall
  }
  const getPreviousHallName = () => {
    if (hallNumber === 1) {
      return null
    }
    const hall = halls.filter(item=>item.id === hallNumber-1)[0]
    return hall
  }
  return (
    <Page hallBg={hallBg} >
      <div id="banner">
        <img src={poster} alt="event-banner" />
      </div>
      <div id="hall">
        <Row noGutters className="justify-content-center">
          <Col md={1}></Col>
          {booths.map((booth,index) => {
            return (
              <>
                {
                  (index!==0 && index % boothPerRow === 0)  &&
                  <>
                    <Col md={1}></Col>
                    <Col md={1}></Col>
                  </>
                }
                <Col md={2}>
                  <Link to={`/booths/${booth.id}`} key={booth.id}>
                    <LazyLoadImage
                      className="booths" 
                      src={booth.hall_image.url}
                      alt="booth"
                      effect="opacity"
                    />
                  </Link>
                </Col>
              </>
            )
          })}
          <Col md={1}></Col>
        </Row>
        {
          getPreviousHallName() && (
            <Link to={`/exhibition-hall/${getPreviousHall()}`} className="prev-btn" >
              <p>
                <svg width="13px" height="22px" viewBox="0 0 13 22">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-209.000000, -3123.000000)">
                      <g transform="translate(100.000000, 3068.000000)">
                        <g transform="translate(103.000000, 54.000000)">
                          <polygon points="0 0 24 0 24 24 0 24"></polygon>
                          <path
                            fill="#BBBDBF"
                            d="M13.5,5.5 C14.6,5.5 15.5,4.6 15.5,3.5 C15.5,2.4 14.6,1.5 13.5,1.5 C12.4,1.5 11.5,2.4 11.5,3.5 C11.5,4.6 12.4,5.5 13.5,5.5 Z M9.8,8.9 L7.24,21.81 C7.11,22.42 7.59,23 8.22,23 L8.3,23 C8.77,23 9.17,22.68 9.28,22.22 L10.9,15 L13,17 L13,22 C13,22.55 13.45,23 14,23 C14.55,23 15,22.55 15,22 L15,16.36 C15,15.81 14.78,15.29 14.38,14.91 L12.9,13.5 L13.5,10.5 C14.57,11.74 16.12,12.63 17.86,12.91 C18.46,13 19,12.52 19,11.91 C19,11.42 18.64,11.01 18.15,10.93 C16.63,10.68 15.37,9.78 14.7,8.6 L13.7,7 C13.14,6.11 12.02,5.75 11.05,6.16 L7.22,7.78 C6.48,8.1 6,8.82 6,9.63 L6,12 C6,12.55 6.45,13 7,13 C7.55,13 8,12.55 8,12 L8,9.6 L9.8,8.9 Z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </p>
              <p>To {getPreviousHallName().name}</p>
            </Link>
          )
        }
        {
          getNextHallName() && (
            <Link to={`/exhibition-hall/${getNextHall()}`} className="next-btn" >
              <p>
                <svg width="13px" height="22px" viewBox="0 0 13 22">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-209.000000, -3123.000000)">
                      <g transform="translate(100.000000, 3068.000000)">
                        <g transform="translate(103.000000, 54.000000)">
                          <polygon points="0 0 24 0 24 24 0 24"></polygon>
                          <path
                            fill="#BBBDBF"
                            d="M13.5,5.5 C14.6,5.5 15.5,4.6 15.5,3.5 C15.5,2.4 14.6,1.5 13.5,1.5 C12.4,1.5 11.5,2.4 11.5,3.5 C11.5,4.6 12.4,5.5 13.5,5.5 Z M9.8,8.9 L7.24,21.81 C7.11,22.42 7.59,23 8.22,23 L8.3,23 C8.77,23 9.17,22.68 9.28,22.22 L10.9,15 L13,17 L13,22 C13,22.55 13.45,23 14,23 C14.55,23 15,22.55 15,22 L15,16.36 C15,15.81 14.78,15.29 14.38,14.91 L12.9,13.5 L13.5,10.5 C14.57,11.74 16.12,12.63 17.86,12.91 C18.46,13 19,12.52 19,11.91 C19,11.42 18.64,11.01 18.15,10.93 C16.63,10.68 15.37,9.78 14.7,8.6 L13.7,7 C13.14,6.11 12.02,5.75 11.05,6.16 L7.22,7.78 C6.48,8.1 6,8.82 6,9.63 L6,12 C6,12.55 6.45,13 7,13 C7.55,13 8,12.55 8,12 L8,9.6 L9.8,8.9 Z"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </p>
              <p>To {getNextHallName().name}</p>
            </Link>
          )
        }
      </div>
    </Page>
  )
}
