const getButtonPos = (boothType) => {
  switch (boothType){
    case 1:
      return {
        vid: `
          top: 48.5%;
          left: 67.5%;
        `,
        image: `
          top: 50%;
          left: 40%;
        `,
        chat: `
          top: 67.5%;
          left: 67%;
        `,
        pdf: `
          top: 43%;
          left: 78.5%;
        `,
        info: `
          top: 6%;
          left: 73%;
        `,
      }
    case 2:
      return {
        vid: `
          top: 50%;
          left: 31.5%;
        `,
        image: `
          top: 50%;
          left: 60%;
        `,
        chat: `
          top: 72.5%;
          left: 25%;
        `,
        pdf: `
          top: 43%;
          left: 79.5%;
        `,
        info: `
          top: 7.5%;
          left: 40%;
        `,
      }
    case 3:
      return {
        vid: `
          top: 49%;
          left: 75%;
        `,
        image: `
          top: 40%;
          left: 32%;
        `,
        chat: `
          top: 67.5%;
          left: 91%;
        `,
        pdf: `
          top: 42%;
          left: 63%;
        `,
        info: `
          top: 4.5%;
          left: 60%;
        `,
      }
    case 4:
      return {
        vid: `
          top: 46%;
          left: 69%;
        `,
        image: `
          top: 50%;
          left: 35%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 69%;
          left: 51%;
        `,
        info: `
          top: 18%;
          left: 63%;
        `,
        zoom: `
          top: 82.5%;
          left: 72.5%;
        `,
      }
    case 5:
      return {
        vid: `
          top: 48%;
          left: 65.5%;
        `,
        image: `
          top: 50%;
          left: 32%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 65.5%;
          left: 48.5%;
        `,
        info: `
          top: 18%;
          left: 43%;
        `,
      }
    default:
      return {}
  }
}
export { getButtonPos };