import LogoL from './images/logo.png'
import Entrance from './images/backdrops/entrance.jpg'
import EntranceP from './images/backdrops/entranceP.jpg'
import LobbyL from './images/backdrops/lobby.jpg'
import Hall from './images/backdrops/hall_layout.jpg'
import Auditorium from './images/backdrops/auditorium.jpg'
import NetworkingL from './images/backdrops/networking.jpg'
import LobbyMusic from './images/audio/lobby.mp3'
import routes from './routes'
import Gallerium from './images/backdrops/gallerium.jpg'
import CountdownL from './images/registration/countdownL.jpg'
import CountdownP from './images/registration/countdownP.jpg'
import PostRegisterL from './images/registration/registrationL.jpg'
import PostRegisterM from './images/registration/registrationP.jpg'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://mcm2021.herokuapp.com',
}

const API_KEYS = {
  amplitude: '0a1dc4d17f623498f956cadc88949115',
}

const eventDays = [12,13,14,15,16,17,18,19,20,21]

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  logo: {
    landscape: LogoL,
    portrait: LogoL,
  },
  postRegister: {
    landscape: PostRegisterL,
    portrait: PostRegisterM,
  },
  countdown: {
    landscape: CountdownL,
    portrait: CountdownP,
  },
  entrance: {
    landscape: Entrance,
    portrait: EntranceP,
  },
  lobby: {
    landscape: LobbyL,
    portrait: LobbyL,
  },
  auditorium1: Auditorium,
  auditorium2: Auditorium,

  exhibition1: Hall,
  exhibition2: Hall,

  networking: {
    landscape: NetworkingL,
    portrait: NetworkingL,
  },
  gallerium: Gallerium,

  lobbyMusic: LobbyMusic,
}

const layout = {
  auditoriumVid: `
    top: 18%;
    bottom: 27.5%;
    left: 7.2%;
    right: 37%;
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    destination: routes.auditorium1,
    css: `
      top: 66.5%;
      left: 33.6%;
      right: 62.4%;
      bottom: 35.9%;
    `,
  },
  {
    id: 'member',
    destination: routes.auditorium2,
    css: `
      top: 66.5%;
      left: 43%;
      right: 53.7%;
      bottom: 35.9%;
    `,
  },
  {
    id: 'exhibition',
    destination: [],
    css: `
      top: 66.5%;
      left: 52.3%;
      right: 44.4%;
      bottom: 35.9%;
    `,
  },
  {
    id: 'agenda',
    destination: 1,
    css: `
      top: 78.5%;
      left: 70%;
      right: 26%;
      bottom: 22%;
    `,
  },
  {
    id: 'gallerium',
    destination: routes.gallerium,
    css: `
      top: 66.5%;
      left: 61.5%;
      right: 35.2%;
      bottom: 35.9%;
    `,
  },
  {
    id: 'helpdesk',
    destination: 0,
    css: `
      top: 90%;
      left: 83.2%;
      right: 13.8%;
      bottom: 15.5%;
    `,
  },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,
  eventDays,
  eventName: 'MCM GIC Members Week 2021',
  googleAnalyticsID: '',

  ...config,
}
