import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'

import LoginPage from '../pages/LoginPage'
import WelcomePage from '../pages/WelcomePage'
import config from '../config'
import routes from '../routes'
import banner from '../images/backdrops/banner-black.png'

const Page = styled.div`
  background: url(${config.assets.entrance.landscape}) no-repeat center center
    fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`

export default function EntrancePages({ children }) {
  return (
    <Page>
      <img src={banner} alt="event-banner" width="60%" className="mt-5" />
      <Switch>
        <Route exact path={routes.root} component={WelcomePage} />
        <Route path={routes.login} component={LoginPage} />
      </Switch>
    </Page>
  )
}
