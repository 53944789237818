import React from 'react'
import { Modal, Carousel } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'
import agenda1 from '../../images/event/Agenda1.jpg'
import agenda2 from '../../images/event/Agenda2.jpg'
import agenda3 from '../../images/event/Agenda3.jpg'
import agenda4 from '../../images/event/Agenda4.jpg'
import agenda5 from '../../images/event/Agenda5.jpg'
import agenda6 from '../../images/event/Agenda6.jpg'
import agenda7 from '../../images/event/Agenda7.jpg'

const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
  }
  .modal-body {
    padding: 0;
  }
`
const StyledCarousel = styled(Carousel)`
  .carousel-control-next, 
  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-prev {
    left: -3%;
  } 
  .carousel-control-next {
    right: -3%;
  }
  span.carousel-control-next-icon, span.carousel-control-prev-icon {
    background-color: ${COLORS.primary};
    padding: 8px;
    border-radius: 8px;
    border: 4px solid ${COLORS.primary};
  }
`

export default function AgendaModal({ showModal, hideSchedule }) {
  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="lg"
      aria-labelledby="speakers-bio"
      centered
    >
      <Modal.Body>
        <StyledCarousel>
          {
            [agenda1,agenda2,agenda3,agenda4,agenda5,agenda6,agenda7].map((image, index)=>(
              <Carousel.Item key={`agenda-${index}`} >
                <img 
                  src={image} 
                  className="d-block mx-auto"
                  width="100%" 
                  alt={`agenda-${index}`}
                />
              </Carousel.Item>
            ))
          }
        </StyledCarousel>
      </Modal.Body>
    </StyledModal>
  )
}
