import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { COLORS, SIZES } from '../../styles/theme';
import config from '../../config'
import routes from '../../routes'
import banner from '../../images/registration/banner.png'
import bannerM from '../../images/registration/mobile-banner.png'
import logo from '../../images/registration/event-logo.png'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.postRegister.landscape});
  background-repeat: no-repeat;
  background-size: cover;
  ${SIZES.mobile} {
    padding: 3rem 0;
    background-image: url(${config.assets.postRegister.portrait});
    background-size: cover;
    font-size: 70%;
  }
  #content {
    color: ${COLORS.primary};
    padding-top: 2rem;
    #header {
      img#landscape {
        display: block;
      }
      img#portrait {
        display: none;
      }
      ${SIZES.mobile} {
        img#portrait {
          display: block;
          margin: 0 auto;
        }
        img#landscape {
          display: none;
        }
      }
    }
  }
  #center {
    width: 25%; 
    ${SIZES.mobile} {
      width: 60%; 
      margin-top: 48px;
    }
  }
  #action-button {
    width: 100%; 
    ${SIZES.mobile} {
      width: 100%; 
    }
    a,
    button {
      background: #FD0000;
      /* border: 2px solid ${COLORS.primary}; */
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 50px auto;
      /* margin-bottom: 125px; */
      font-size: 1.2em;
      color: #FFFFFF;
      font-weight: 500;
      padding: 12px 35px;
      border-radius: 56px;
      font-style: italic ;

      div {
        line-height: 32px;
      }
      div.arrow {
        line-height: 24px;
        font-size: 1.4em;
        align-self: flex-end;
      }
      ${SIZES.mobile} {
        font-size: 1.2em;
        width: 100%;
        padding: 10px 18px;
      }
    }
  }
`

export default function PreRegisterPage() {

  return (
    <Page>
      <div id="content">
        <div id="header" className="align-self-end">
          <img id="landscape" src={banner} width="60%" alt="event-banner" />
          <img id="portrait" src={bannerM} width="100%" alt="event-banner" />
        </div>
        <div id="center" className="mx-auto">
          <img src={logo} width="100%" alt="event-logo" />
          <div id="action-button" className="align-self-end">
            <Button variant="default" as={Link} to={routes.register}>
              <div>REGISTER HERE</div>
              <div className="arrow">⇀</div>
            </Button>
          </div>
        </div>
      </div>
    </Page>
  )
}