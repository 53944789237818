import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyDgF-AojgDqzWFhfA7RFDbBDjsPHQhjStU',
  authDomain: 'mcm2021-38f22.firebaseapp.com',
  projectId: 'mcm2021-38f22',
  storageBucket: 'mcm2021-38f22.appspot.com',
  messagingSenderId: '545725755761',
  appId: '1:545725755761:web:078ebf58506ce5805346d0',
  // measurementId: 'G-MYCN9XLCDX',
}

firebase.initializeApp(firebaseConfig)

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const ChatDb = firebaseDb.ref('chat')
const ChatAuditoriumDb = firebaseDb.ref('chatAuditorium')
const ChatMemberLoungeDb = firebaseDb.ref('chatMemberLounge')
const NoticeDb = firebaseDb.ref('notice')
const AccessDb = firebaseDb.ref('access')
const AuditoriumDb = firebaseDb.ref('auditorium')
const LiveLinkDb = firebaseDb.ref('vimeolink')
const NotificationDb = firebaseDb.ref('pushNotifications')

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  ChatAuditoriumDb,
  ChatMemberLoungeDb,
  NoticeDb,
  NotificationDb,
  AccessDb,
  AuditoriumDb,
  LiveLinkDb,
}
