import React from 'react'
import { Modal } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
  }
  h6 {
    color: ${COLORS.primary};
  }
  .file-container {
    display: flex;
    justify-content: space-between;
    button {
      background: transparent;
      border: none;
      color: ${COLORS.primaryLight};
    }
  }
  .modal-content-button {
    background: #5461a2;
    color: white;
    font-size: 12px;
    border: none;
    border-radius: 20px;
    padding: 5px 20px;
    margin-bottom: 4px;
    text-decoration: none;
  }
`

export default function DownloadModal({ files, downloadOpen, setDownloadOpen }) {
  return (
    <StyledModal
      show={downloadOpen}
      onHide={()=>setDownloadOpen(false)}
      aria-labelledby="download-modal"
      centered
    >
      <Modal.Header closeButton>
        <h6 id="download-modal">Select file(s) to preview</h6>
      </Modal.Header>
      <Modal.Body>
        {
          files.map(file=>(
            <div key={file.filename} className="file-container">
              <p className="m-0">{file.filename}</p>
              <a href={file.url} 
                target="_blank" 
                rel="noopener noreferrer"
                className="modal-content-button"
              >
                View
                {/* <button>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={COLORS.primaryLight} strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke={COLORS.primaryLight} d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline stroke={COLORS.primaryLight} points="7 10 12 15 17 10"></polyline>
                    <line stroke={COLORS.primaryLight} x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                </button> */}
              </a>
            </div>
          ))
        }
      </Modal.Body>
    </StyledModal>
  )
}
