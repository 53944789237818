export default {
  root: '/',
  
  preRegister: '/registration',
  register: '/registration/form',
  postRegister: '/registration/complete',
  forgotPassword: '/registration/forgot-password',
  resetPassword: '/registration/create-password',
  login: '/login',

  notifications: '/notifications',
  menu: '/menu',
  help: '/help',

  lobby: '/lobby',

  networking: '/networking-lounge',

  auditorium1: '/auditorium-hall',
  auditorium2: '/members-lounge',

  exhibition1: '/exhibition-hall/1',
  exhibition2: '/exhibition-hall/2',
  exhibition3: '/exhibition-hall/3',
  exhibition4: '/exhibition-hall/4',
  exhibition5: '/exhibition-hall/5',
  exhibition6: '/exhibition-hall/6',
  exhibition7: '/exhibition-hall/7',

  gallerium: '/gallery',
}
