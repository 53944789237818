import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import { useDispatch } from 'react-redux'
import { showGlobalModal, muteMusic, unmuteMusic } from '../ducks/layout'
import config from '../config'
import routes from '../routes'
import { SIZES } from '../styles/theme'
import { ChatAuditoriumDb, ChatMemberLoungeDb, AccessDb, AuditoriumDb, LiveLinkDb } from '../firebaseConfig'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useObject } from 'react-firebase-hooks/database'
import ChatSection from '../components/ChatSection'
import PasscodeModal from '../components/GlobalModals/PasscodeModal'
import { startTrack, endTrack } from '../utils/timerHelper'
import { usePageActiveUserCount } from '../utils/activeUserCount'
import IframeModal from '../components/GlobalModals/IframeModal'
import vote from '../images/event/vote.png'

const Page = styled.div`
  overflow: hidden;
  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    background: black;

    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .chat {
    max-width: 350px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 67%;
    bottom: 0;
    right: 5%;
    z-index: 30;
  }
  .attendance {
    position: fixed;
    top: 10%;
    left: 5%;
    display: flex;
    animation: heartbeat 5s infinite;

    .action-label {
      color: white;
      font-weight: 500;
      margin: 0 16px;
      font-style: italic;
      align-self: center;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0 auto;
      img {
        border-radius: 50%;
      }
    }
    &:hover {
      .action-label,
      img {
        transition: 500ms;
        transform: scale(1.2);
      }
      img {
        box-shadow: 0px 0px 30px 6px #ffad2c;
      }
    }
  }
  .actions {
    position: fixed;
    top: 79%;
    bottom: 17%;
    left: 7.5%;
    right: 37%;
    display: flex;
    justify-content: center;
    button {
      width: 150px;
      background-image: linear-gradient(#492777, #c4000c);
      border: 2px solid #e20613;
      border-radius: 16px;
      color: white;
      font-style: italic;
      font-weight: bold;
      font-size: 12px;
      margin: 0 8px;
      letter-spacing: 1px;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 10px 10px -10px #e20613;
        animation-name: bounce;
        animation-duration: 1s;
        animation-fill-mode: both;
      }
    }
    div {
      width: 150px;
      margin-top: -10px;
      img {
        cursor: pointer;
        &:hover {
          animation-name: bounce;
          animation-duration: 1s;
          animation-fill-mode: both;
        }
      }
    }
  }
  #mc-logo {
    width: 15%;
    position: fixed;
    top: 33%;
    bottom: 40%;
    left: 2%;
  }
`

export default function AuditoriumPage({
  liveUrl,
  hallBg,
  hallNumber,
  currentUser,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [authenticated, setAuthenticated] = useState(hallNumber===1 ? true : false )
  const [showModal, setShowModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [snapshot, loading, error] = useObject(AccessDb)
  const [accessSnapshot, accessLoading, accessError] = useObject(AuditoriumDb)
  const [liveSnapshot, liveLoading, liveError] = useObject(LiveLinkDb)
  const history = useHistory()
  const dispatch = useDispatch()
  const initialPath = history.location.pathname.substr(1)

  const playBackLink = {
    title: "Event Playbacks",
    link: 'https://vimeo.com/showcase/8238916/embed',
  }

  usePageActiveUserCount(currentUser)

  // useEffect(() => {
  //   if (currentUser.id){
  //     startTrack(currentUser)
  //   }
    
  //   return () => {
  //     if (currentUser.id){
  //       endTrack(currentUser, initialPath)
  //     }
  //   }
  // }, [currentUser.id])

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Conference`
  }, [])

  useEffect(() => {
    if (!accessLoading) {
      if (hallNumber === 1) {
        if (accessSnapshot.val()){
          amplitude.getInstance().logEvent(`Visit Auditorium`)
        }
        else {
          toast('Auditorium Hall currently not available. Please try again later.', {
            toastId: 'auditoriumToast',
          })
          history.push(routes.lobby)
        }
      }
    }
  }, [accessLoading, accessSnapshot, currentUser.id, hallNumber])

  useEffect(() => {
    if (!loading) {
      if (hallNumber === 2) {
        if (!authenticated) {
          setShowModal(true)
        } 
        else {
          if (!snapshot.val()) {
            toast('Welcome! Live event will only be available on 20 March, 2021 (Sat) 2PM.', {
              toastId: 'eventToast',
            })
            history.push(routes.lobby)
          } else {
            amplitude.getInstance().logEvent(`Visit Member Lounge`)
          }
        }
      }
    }
  }, [loading, snapshot, currentUser.id, hallNumber, authenticated])

  const hideModal = () => {
    setShowModal(false)
  }

  const showVideo = () => {
    amplitude.getInstance().logEvent('Click Playbacks')
    setIsOpen(true)
  }

  const displayAgenda = () => {
    amplitude.getInstance().logEvent('Click Agenda')
    dispatch(showGlobalModal(hallNumber === 1 ? 'agenda' : 'member-agenda'))
  }

  const showVote = () => {
    amplitude.getInstance().logEvent(`Click Auditorium Vote`)
    window.open(
      "http://bit.ly/MCM-UDGP-The-Best-UDGP-Presentation-Voting-Form",
      "_blank"
    )
  }

  if (loading || accessLoading || liveLoading) {
    return <LoadIcon />
  }
  if (authenticated) {
    return (
      <Page>
        <PageWithBg bgImg={hallBg}>
          <div className="content">
            {isLoading && <LoadIcon />}
            <iframe
              title="Live Broadcast"
              src={hallNumber === 1 ? liveSnapshot.val() : liveUrl}
              allow="autoplay; fullscreen"
              allowFullScreen
              frameBorder="0"
              onLoad={() => {
                setIsLoading(false)
              }}
            />
          </div>
          <div className="chat h-100">
            <ChatSection
              currentUser={currentUser}
              firebaseDb={hallNumber === 1 ? ChatAuditoriumDb : ChatMemberLoungeDb}
              hallName={hallNumber === 1 ? 'Auditorium' : 'MemberLounge'}
            />
          </div>
          <div className="actions">
            <button onClick={displayAgenda}>Agenda</button>
            {
              hallNumber === 1 &&
              <button onClick={showVideo}>Playbacks</button>
            }
            {
              hallNumber === 1 &&
              <div onClick={showVote}>
                <img src={vote} alt="vote" width="100%" />
              </div>
            }
          </div>
        </PageWithBg>
        <IframeModal
          iframe={playBackLink}
          iframeOpen={isOpen}
          setIframeOpen={setIsOpen}
        />
      </Page>
    )
  }
  return (
    <PasscodeModal hideModal={hideModal} showModal={showModal} setAuthenticated={setAuthenticated} />
  )
}
