import React, { useRef } from 'react'
import { Modal, Carousel } from 'react-bootstrap'
import styled from 'styled-components'
import webinar from '../../images/event/WebinarsAgenda.jpg'
import agenda1 from '../../images/event/Agenda1.jpg'
import agenda2 from '../../images/event/Agenda2.jpg'
import agenda3 from '../../images/event/Agenda3.jpg'
import agenda4 from '../../images/event/Agenda4.jpg'
import agenda5 from '../../images/event/Agenda5.jpg'
import agenda6 from '../../images/event/Agenda6.jpg'
import agenda7 from '../../images/event/Agenda7.jpg'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import { COLORS } from '../../styles/theme'

const StyledModal = styled(Modal)`
  text-align: center;
  color: #002060;

  .modal-dialog {
    min-width: 75%;
  }
  .modal-content {
    background-color: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 0;
    padding: 24px 36px;
    margin-bottom: 24px;

    .modal-header {
      border: none;
      padding: 0;
      button {
        font-size: 36px;
        opacity: 1;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .header-section {
      margin-top: 16px;
      button {
        border: none;
        background: transparent;
        color: #002060;
        display: block;
      }
      .navigation {
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        button {
          /* width: 150px; */
          color: ${COLORS.primary};
          background-color: white;
          border: 2px solid ${COLORS.primary};
          border-radius: 8px;
          padding: 6px 16px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          h5 {
            margin: 0;
          }
          &:hover {
            background: #FFAD2C;
          }
        }
      }
    }
    .divider {
      background: ${COLORS.primary};
      height: 4.5px;
      margin: 32px 0;
    }
  }
  .back-top {
    background: #002060;
    border: none;
    border-radius: 12px;
    position:absolute;
    bottom: 0;
    right: 0;
    svg {
      width:50;
      height:50;
    }
  }
  .modal-body {
    padding-top: 0px;
    padding-bottom: 36px;
  }
  .agenda {
    button {
      margin: 16px;
      background-color: ${COLORS.primary};
      border: 2px solid #FFAD2C;
      font-weight: 500;
      border-radius: 32px;
      padding: 4px 12px;
      color: white;
      font-size: 20px;
    }
    .active {
      /* font-style: italic; */
      color: #FFAD2C;
      text-decoration: underline;
    }
  }
`
const StyledCarousel = styled(Carousel)`
  .carousel-control-next, 
  .carousel-control-prev {
    opacity: 1;
  }

  span.carousel-control-next-icon, span.carousel-control-prev-icon {
    background-color: ${COLORS.primary};
    padding: 8px;
    border-radius: 8px;
    border: 4px solid ${COLORS.primary};
  }
`

export default function ProgrammeModal({ showModal, hideSchedule, currentUser }) {
  const speakerSec = useRef()
  const agendaSec = useRef()
  const startModal = useRef()

  const scrollTop = (ref) =>{
    ref.current.scrollIntoView({ behavior: 'smooth' })
  };
  
  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="xl"
      aria-labelledby="menu-modal"
      centered
    >
      <Modal.Header closeButton>
        <span ref={startModal}></span>
      </Modal.Header>
      <Modal.Body>
        <div className="header-section">
          <div className="navigation">
            <button onClick={()=>scrollTop(agendaSec)}>
              <h5>Agenda</h5>
            </button>
            <button onClick={()=>scrollTop(speakerSec)}>
              <h5>Webinar Agenda</h5>
            </button>
          </div>
        </div>
        <div className="divider"></div>
        <span ref={agendaSec}></span>
        <div className="container">
          <LazyLoadComponent>
            <StyledCarousel>
              {
                [agenda1,agenda2,agenda3,agenda4,agenda5,agenda6,agenda7].map((image, index)=>(
                  <Carousel.Item key={`speakerinfo-${index}`} >
                    <img 
                      src={image} 
                      className="d-block mx-auto"
                      width="80%" 
                      alt={`agenda-${index}`}
                    />
                  </Carousel.Item>
                ))
              }
            </StyledCarousel>
          </LazyLoadComponent>
        </div>
        <div className="divider"></div>
        <div>
          <div ref={speakerSec}></div>
          <LazyLoadImage effect="black-and-white" src={webinar} width="90%" alt="webinar agenda" />
        </div>
        <button onClick={()=>scrollTop(startModal)} className="back-top">
          <svg width="45" height="45" viewBox="0 0 45 45">
            <g transform="translate(-1767 -3747)">
              <g transform="translate(120 -2428)">
                <path d="M22.175,9.358a1.43,1.43,0,0,0-1.43-1.43H5.063l5.813-5.42A1.43,1.43,0,1,0,8.923.421L.455,8.311a1.43,1.43,0,0,0,0,2.091L8.923,18.3A1.43,1.43,0,1,0,10.875,16.2L5.063,10.788H20.746A1.43,1.43,0,0,0,22.175,9.358Z" transform="translate(1678.681 6187) rotate(90)" fill="#ffad2c"/>
              </g>
            </g>
          </svg>
        </button>
      </Modal.Body>
    </StyledModal>
  )
}
