import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import amplitude from 'amplitude-js'
import routes from '../../routes'
import { toggleMute } from '../../ducks/layout'
import { logoutUser } from '../../ducks/auth'
import logo from '../../images/registration/event-logo.png'
import lobby from '../../images/mobile/lobby.jpg'
import meeting from '../../images/mobile/meeting.png'
import mute from '../../images/mobile/mute.png'
import logout from '../../images/mobile/logout-nav.png'
import expo from '../../images/mobile/expo.png'
import vote from '../../images/mobile/vote.png'
import gallerium from '../../images/mobile/gallerium.png'
import gallery from '../../images/mobile/virtual-gallery.png'
import MobileNotice from './MobileNotice'
import config from '../../config'
import { usePageActiveUserCount } from '../../utils/activeUserCount'

const MobileLobby = ({ currentUser }) => {
  const dispatch = useDispatch()
  const { muted } = useSelector((state) => state.layout)

  usePageActiveUserCount(currentUser)

  const toggleAudio = () => {
    amplitude.getInstance().logEvent('Click Mute button')
    dispatch(toggleMute())
  }

  useEffect(() => {
    amplitude.getInstance().logEvent('Visit Lobby')
  }, [currentUser.id])

  const handleVoteForm = () => {
    amplitude.getInstance().logEvent("Click Vote")
    window.open("https://us4.list-manage.com/survey?u=6582b2f846ce6a6214d849b1e&id=4cba1f1209&attribution=false", '_blank')
  }

  const handleLogout = () => {
    amplitude.getInstance().logEvent("Click Logout")
    dispatch(logoutUser())
  }
  return (
    <Page>
      <img className="logo" src={logo} alt="event-logo" />
      <MobileNotice />
      <div className="menu">
        <p className="title">Virtual Halls</p>
        <div className="btn-container">
          <div className="mute-button" onClick={toggleAudio}>
            <img className="mute-icon" src={mute} />
            <p className="mute-label">{muted ? 'Unmute' : 'Mute'}</p>
          </div>
          <div className="logout-button" onClick={handleLogout}>
            <img className="logout-icon" src={logout} />
            <p className="logout-label">Logout</p>
          </div>
        </div>
        <div className="list-wrapper">
          <div className="item-box">
            <div className="icon-wrapper">
              <img className="expo" src={meeting} alt="icon"/>
            </div>
            <p className="item-label">Auditorium</p>
            <Link to={routes.auditorium1} className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="expo" src={meeting} alt="icon"/>
            </div>
            <p className="item-label">Members Lounge</p>
            <Link to={routes.auditorium2} className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="expo" src={expo} alt="icon" />
            </div>
            <p className="item-label">Exhibition Hall</p>
            <Link to="/exhibition-halls" className="item-button">
              Enter
            </Link>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="expo" src={gallery} alt="icon"/>
            </div>
            <p className="item-label">Special Deals</p>
            <Link to="/special-deals" className="item-button">
              Enter
            </Link>
          </div>
          <div className="item-box">
            <div className="icon-wrapper">
              <img className="expo" src={vote} alt="icon"/>
            </div>
            <p className="item-label">People's Choice</p>
            <div onClick={handleVoteForm} className="item-button">
              Enter
            </div>
          </div>

          <div className="item-box">
            <div className="icon-wrapper">
              <img className="gallerium" src={gallerium} alt="icon"/>
            </div>
            <p className="item-label">Gallery</p>
            <Link to={routes.gallerium} className="item-button">
              Enter
            </Link>
          </div>
        </div>
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${config.assets.postRegister.portrait});
  background-size: cover;
  background-position: top center;

  height: calc(100% - 60px);
  width: 100vw;
  overflow: hidden;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .logo {
    width: 70%;
    display: block;
    margin: 2rem auto;
  }
  .living {
    width: 150px;
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
  }

  .video-wrapper {
    width: 100%;
    position: absolute;
    bottom: 230px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    padding-bottom: 56.25%;
    height: 0;
    background: url(${lobby});
    background-size: cover;
    background-position: 50% 50%;

    iframe {
      position: absolute;
    }
  }

  .menu {
    width: 100%;
    height: 250px;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    background: white;
    border-radius: 20px 20px 0px 0px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .btn-container {
    display: flex;
    position: absolute;
    top: 22px;
    right: 20px;
  }
  .mute-button, .logout-button {
    width: 80px;
    height: 22px;
    display: flex;
    align-items: center;
  }
  .mute-icon, .logout-icon {
    height: 15px;
    margin-right: 6px;
  }

  .mute-label, .logout-label{
    font-size: 12px;
    margin: 0;
  }
  .logout-label {
    color: #492777;
  }

  .list-wrapper {
    width: 100%;
    height: 180px;
    position: absolute;
    top: 50px;
    left: 0;
    overflow-x: scroll;
    display: flex;
    align-items: center;
  }

  .item-box {
    width: 130px;
    height: 160px;
    margin-left: 20px;
    border: solid 3px #FFB100;
    border-radius: 10px;
    background: white;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .icon-wrapper {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-label {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    color: #272727;
    margin: 0px;
  }

  .item-button {
    background-color: #002F62;
    color: white;
    border: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
    width: 90px;
    height: 25px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .meeting1 {
    width: 135%;
    transform: translateY(15%);
  }

  .meeting2 {
    width: 85%;
  }

  .meeting {
    width: 150%;
  }

  .expo {
    width: 170%;
  }

  .gallerium {
    width: 140%;
  }

  .networking {
    width: 110%;
  }

  @media screen and (min-width: 540px) {
    .menu {
      height: 275px;
    }
    .logo {
      width: 50%;
      margin-top: 64px;
    }
  }
`

export default MobileLobby
