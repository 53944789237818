import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js'

import PageWithBg from '../components/PageWithBg'
import LobbyOverlay from '../components/LobbyOverlay'
import LobbyEntry from '../components/LobbyEntry'
import left from '../images/backdrops/left.jpg'
import right from '../images/backdrops/right.jpg'
import bannerList from '../constants/bannerList'
import config from '../config'
import { useDispatch } from 'react-redux'
import { muteMusic, unmuteMusic } from '../ducks/layout'
import { usePageActiveUserCount } from '../utils/activeUserCount'
import { toast } from 'react-toastify'

const PageWrapper = styled.div`
  .trailer {
    background-color: black;
    position: absolute;
    top: 6.5%;
    left: 33.7%;
    right: 34.7%;
    bottom: 61.0%;
  }
  .left {
    /* border: 1px solid red; */
    position: absolute;
    top: 6.5%;
    left: 24%;
    right: 67%;
    bottom: 73.8%;
  }
  .right {
    /* border: 1px solid red; */
    position: absolute;
    top: 6.5%;
    left: 66%;
    right: 25%;
    bottom: 73.8%;
  }
  .banners {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    width: 12%;
    height: calc(100vh - 20%);
    top: 6%;
    left: 9%;
    div{
      /* width: 12%; */
      cursor: pointer;
      img {
        transition: 500ms;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  #vote-btn {
    background: linear-gradient(180deg, #fff, #c8c801, #c8c801, #3e542a);
    border: 2px solid lightgray;
    border-radius: 16px;
    font-size: 20px;
    padding: 8px 16px;
    font-weight: 600;
    color: #3e542a;
    position: absolute;
    top: 45%;
    right: 13.5%;
  }
`

export default function LobbyPage({ currentUser }) {
  const [overlayTitle, setOverlayTitle] = useState('Select hall to enter:')
  const [overlay, setOverlay] = useState(null)
  const dispatch = useDispatch()

  usePageActiveUserCount(currentUser)

  const showEntryOverlay = (destinations) => {
    setOverlay(destinations)
  }

  const hideOverlay = () => setOverlay(null)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      hideOverlay()
    }
  }, [])

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  const showHelpDesk = () => {
    toast("Event has ended.")
    // const cpt = window.chaport
    // cpt.on('ready', function () {
    //   cpt.setVisitorData({
    //     name: currentUser.fullName,
    //     email: currentUser.email,
    //   })
    //   cpt.open()
    // })
    // cpt.on('collapsed', function () {
    //   cpt.close()
    // })
    // amplitude.getInstance().logEvent('Click Lobby Helpdesk')
  }

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
  }, [])

  useEffect(() => {
    amplitude.getInstance().logEvent('Visit Lobby')
  }, [currentUser.id])

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  const handleOpenLink = (banner) => {
    amplitude.getInstance().logEvent("Click Banner", {
      bannerId: banner.id
    })
    window.open(banner.url, '_blank')
  }

  const handleVoteForm = () => {
    amplitude.getInstance().logEvent("Click Vote")
    window.open("https://us4.list-manage.com/survey?u=6582b2f846ce6a6214d849b1e&id=4cba1f1209&attribution=false", '_blank')
  }

  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <LobbyOverlay
          overlayTitle={overlayTitle}
          overlay={overlay}
          hideOverlay={hideOverlay}
        />
        {config.lobbyEntries.map((entry) => {

          return (
            <LobbyEntry
              showHelpDesk={showHelpDesk}
              key={entry.id}
              setOverlayTitle={setOverlayTitle}
              showEntryOverlay={showEntryOverlay}
              {...entry}
            />
          )
        })}
        <div className="trailer">
          <iframe
            width="100%"
            height="100%"
            title="Event Trailer"
            src="https://player.vimeo.com/video/522782284?autoplay=1&loop=1"
            allow="autoplay; fullscreen"
            allowFullScreen
            frameBorder="0"
          />
        </div>
        <div className="left">
          <img width="100%" src={left} alt="organiser logo" />
        </div>
        <div className="right">
          <img width="100%" src={right} alt="sponsor logo" />
        </div>
        <div className="banners">
          {
            bannerList.map((item, index) => (
              <div key={`banner-${index}`} onClick={()=>handleOpenLink(item)}>
                <img width="100%" src={item.image} alt="banner" />
              </div>
            )
          )}
        </div>
        <button onClick={handleVoteForm} id="vote-btn">
          PEOPLE'S CHOICE
        </button>
      </PageWithBg>
    </PageWrapper>
  )
}
