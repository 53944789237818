import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { COLORS } from '../../styles/theme'
import ChatMessage from './ChatMessage'

const Page = styled.div`
  font-size: 0.8em;
  /* height: 100%; */
  /* backdrop-filter: blur(10px); */
  /* background-color: rgba(255,255,255,0.5); */
  /* padding: 32px 24px; */
  height: 65%;

  .card-container {
    height: 100%;
    margin: 16px 0;
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;

    .messages {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
  form#chat-form {
    width: 100%;
    background: transparent;
    text-align: right;

    .form-input {
      display: block;
      width: 100%;
      background: #fff;
      resize: none;
      outline: none;
      border: 3px solid ${COLORS.primary};
      padding: 8px 12px;
      font-size: 12px;
      border-radius: 8px;
      font-weight: 500;
      &::placeholder {
        font-style: italic;
      }
    }
    .btn {
      font-size: 12px;
      font-weight: 600;
      color: ${COLORS.primary};
      background: transparent;
      padding-right: 0;
      cursor: pointer;

      svg {
        margin: 0 4px;
      }
    }
  }
`

export default function ChatCard({
  currentUser,
  handleSubmitMessage,
  handleFetchOlderMessages,
  toggleAvatar,
  messages,
  fetchLoading,
  messagesLoading,
  submitLoading,
  scrollTrigger,
}) {
  const [chat, setChat] = useState('')

  // hardcode for now
  // const topics = ["Topic A", "Topic B", "Topic C"]
  // const [selectedTopic, setSelectedTopic] = useState(topics[0])
  // const pickTopic = (topic) => {
  //   setSelectedTopic(topic)
  // }
  const chatContainerRef = useCallback((node) => {
    if (node) {
      node.scrollTop = 9999999;
    }
  // eslint-disable-next-line
  }, [scrollTrigger]);

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0) {
      handleFetchOlderMessages();
      e.target.scrollTop = 1
    }
  };

  const onChatInput = (e) => {
    setChat(e.target.value)
  }

  const handleChat = (e) => {
    e.preventDefault()
    handleSubmitMessage(chat, currentUser)
    setChat('')
  }

  return (
    <Page>
      <div className="card-container">
        <div className="messages pr-3" ref={chatContainerRef} onScroll={handleScroll}>
          {
            fetchLoading ? <p className="text-center text-secondary">Loading...</p> : ''
          }
          {/* { messagesLoading && <p style={{textAlign: 'center', margin: '20px 0px'}}>Loading...</p> } */}
          {messages.map((message, index) => (
            <ChatMessage key={index} {...message} currentUser={currentUser} toggleAvatar={toggleAvatar} />
          ))}
          <span></span>
        </div>
      </div>
      <form id="chat-form" onSubmit={handleChat}>
        <input
          type="text"
          value={chat}
          onChange={onChatInput}
          className="form-input"
          placeholder="Type a message..."
        />
        <button className="btn" type="submit" disabled={submitLoading || !chat.length}>
          Submit
          <svg width="16" height="13" viewBox="0 0 20 18">
            <g stroke="none" strokeWidth="1" fill={COLORS.primary} fillRule="evenodd">
              <g transform="translate(-374.000000, -1529.000000)">
                <g transform="translate(100.000000, 1428.000000)">
                  <g transform="translate(272.000000, 98.000000)">
                    <g>
                      <polygon fill="none" points="0 0 24 0 24 24 0 24"></polygon>
                      <path d="M3.4,20.4 L20.85,12.92 C21.66,12.57 21.66,11.43 20.85,11.08 L3.4,3.6 C2.74,3.31 2.01,3.8 2.01,4.51 L2,9.12 C2,9.62 2.37,10.05 2.87,10.11 L17,12 L2.87,13.88 C2.37,13.95 2,14.38 2,14.88 L2.01,19.49 C2.01,20.2 2.74,20.69 3.4,20.4 Z"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </button>
      </form>
    </Page>
  )
}
